<template>
  <div v-loading="loading" class="pd-b-3">
    <h4 class="text-center text-primary font-22">
      <span><u>ขั้นตอนที่ 4</u> ยืนยันการกรอกข้อมูล</span>
    </h4>
    <h4 class="is-flex js-between mg-b-7">
      <span>ขั้นตอนที่ 1 กรอกข้อมูลอีเมล</span>
      <el-button
        @click="$emit('backStep', 0)"
        size="mini"
        type="info"
        circle
        class="bg-secondary border-0"
        ><i class="fas fa-pencil-alt"></i
      ></el-button>
    </h4>
    <el-divider class="mg-y-6"></el-divider>
    <div>
      <p class="mg-y-6">
        อีเมล <span class="text-grey">{{ ruleForm.email }}</span>
      </p>
      <!-- <p class="mg-y-6">
        รหัสผ่าน
        <span class="text-grey font-16"
          ><i class="fas fa-circle"></i> <i class="fas fa-circle"></i>
          <i class="fas fa-circle"></i> <i class="fas fa-circle"></i>
          <i class="fas fa-circle"></i> <i class="fas fa-circle"></i>
          <i class="fas fa-circle"></i
        ></span>
      </p> -->
      <div class="font-20 pd-t-5" v-if="ruleForm.consent == 1">
        <el-radio 
          v-model="ruleForm.consent"
          id="consent"
          :label="1"
          class="mg-r-6"
          aria-label="Consent"
        >
         ข้าพเจ้ายินยอม
        <!-- ฉันได้อ่าน และยอมรับ -->
        <!-- <a :href="termLink" target="_blank">เงื่อนไขการใช้บริการ</a> และ -->
        
        </el-radio>
        <span class="color-primary">
           ให้ ธสน. ใช้และ/หรือเปิดเผย ชื่อ-นามสกุล และข้อมูลการติดต่อของข้าพเจ้าให้กับหน่วยงานพันธมิตรของ
          ธสน. ด้านการสนับสนุนทางการเงิน เพื่อการติดต่อประชาสัมพันธ์ และ/หรือ
          เสนอกิจกรรมทางการตลาด ทั้งนี้ ท่านสามารถศึกษาหลักการ ความเป็นส่วนตัวของ ธสน. ได้ที่ <a href="https://www.exim.go.th/th/privacypolicy.aspx" target="_blank"><u>https://www.exim.go.th/th/privacypolicy.aspx</u></a>
        </span>
    </div>
    <div class="font-20 pd-t-5" v-if="ruleForm.consent == 0">
      <el-radio 
        v-model="ruleForm.consent"
        :label="0"
        id="consent"
        class="mg-r-6"
        aria-label="Consent"
      >
      ข้าพเจ้าไม่ยินยอม
      <!-- ฉันได้อ่าน และยอมรับ -->
      <!-- <a :href="termLink" target="_blank">เงื่อนไขการใช้บริการ</a> และ -->
      
      </el-radio>
      <span class="color-primary">
          ให้ ธสน. ใช้และ/หรือเปิดเผย ชื่อ-นามสกุล และข้อมูลการติดต่อของข้าพเจ้าให้กับหน่วยงานพันธมิตรของ
          ธสน. ด้านการสนับสนุนทางการเงิน เพื่อการติดต่อประชาสัมพันธ์ และ/หรือ
          เสนอกิจกรรมทางการตลาด ทั้งนี้ ท่านสามารถศึกษาหลักการ ความเป็นส่วนตัวของ ธสน. ได้ที่ <a href="https://www.exim.go.th/th/privacypolicy.aspx" target="_blank"><u>https://www.exim.go.th/th/privacypolicy.aspx</u></a>
      </span>
    </div>
    </div>
    <h4 class="is-flex js-between mg-b-7">
      <span>ขั้นตอนที่ 2 กรอกข้อมูลส่วนตัว</span
      ><el-button
        @click="$emit('backStep', 1)"
        size="mini"
        type="info"
        circle
        class="bg-secondary border-0"
        ><i class="fas fa-pencil-alt"></i
      ></el-button>
    </h4>
    <el-divider class="mg-y-6"></el-divider>
    <div>
      <div class="box--profile overflow-hidden el-upload-list--picture-card">
        <div class="el-upload--picture-card overflow-hidden mg-x-auto mg-y-5">
          <div v-if="ruleForm.profileImg && ruleForm.profileImg != 'undefined'">
            <img :src="ruleForm.profileImg.img" alt="" />
          </div>
          <div v-else>
            <avatar :username="ruleForm.name" :rounded="false" :size="150">
            </avatar>
          </div>
        </div>
      </div>

      <!-- <p class="mg-y-6">
        รูปโปรไฟล์
        <span class="text-grey" v-if="ruleForm.profileImg">{{
          ruleForm.profileImg.name
        }}</span
        ><span class="text-grey" v-else> <i>ไม่ได้อัพโหลดรูป</i></span>
      </p> -->
      <!-- <p class="mg-y-6">
        คำนำหน้าชื่อ <span class="text-grey">{{ titleName }}</span>
      </p> -->
      <p class="mg-y-6">
        ชื่อ <span class="text-grey">{{ ruleForm.name }}</span>
      </p>
      <p class="mg-y-6">
        นามสกุล <span class="text-grey">{{ ruleForm.surname }}</span>
      </p>
      <!-- <p class="mg-y-6">
        เพศ
        <span class="text-grey">{{
          ruleForm.gender == 1 ? "ชาย" : "หญิง"
        }}</span>
      </p> -->
      <!-- <p class="mg-y-6">
        อายุ <span class="text-grey">{{ ruleForm.age }}</span>
      </p> -->
      <!-- <p class="mg-y-6">
        วุฒิการศึกษา <span class="text-grey">{{ education }}</span>
      </p> -->
      <p class="mg-y-6">
        เบอร์โทรศัพท์มือถือ
        <span class="text-grey">{{ ruleForm.mobilephone }}</span>
      </p>
    </div>
    <h4 class="is-flex js-between mg-b-7">
      <span>ขั้นตอนที่ 3 กรอกข้อมูลบริษัท</span>
      <el-button
        @click="$emit('backStep', 2)"
        size="mini"
        type="info"
        circle
        class="bg-secondary border-0"
        ><i class="fas fa-pencil-alt"></i
      ></el-button>
    </h4>
    <el-divider class="mg-y-6"></el-divider>
    <div>
      <p class="mg-y-6">
        ชื่อบริษัท <span class="text-grey">{{ ruleForm.companyName }}</span>
      </p>
      <p class="mg-y-6">
        เลขนิติบุคคล
        <span class="text-grey">{{ ruleForm.corporateNumber }}</span>
      </p>
      <!-- <p class="mg-y-6">
        ตำแหน่ง <span class="text-grey">{{ position }}</span>
      </p> -->
      <p class="mg-y-6">
        ลักษณะธุรกิจ <span class="text-grey">{{ businessType }}</span>
      </p>
      <p
        class="mg-y-6"
        v-if="
          cateIndustryType &&
          (ruleForm.businessType == 2 || ruleForm.businessType == 3)
        "
      >
        ประเภทของอุตสาหกรรม
        <span class="text-grey">
          {{ cateIndustryType }}
        </span>
      </p>

      <p class="mg-y-6" v-if="ruleForm.industryType != null">
        ประเภทย่อยของอุตสาหกรรม
        <span v-if="ruleForm.industryType == 0" class="text-grey">
          {{ ruleForm.industryTypeEtc }}
        </span>
        <span v-else class="text-grey">{{ industryType }}</span>
      </p>
      <p class="mg-y-6">
        หน่วยงาน
        <span v-for="(data, index) in memberOf" :key="index" class="text-grey">
          {{ data.etc }}<span v-if="index + 1 < memberOf.length">, </span>
        </span>
      </p>
      <!-- <p class="mg-y-6">
        การจ้างงาน <span class="text-grey">{{ employment }}</span>
      </p> -->
      <!-- <p class="mg-y-6">
        ปีที่จัดตั้งบริษัท
        <span class="text-grey">{{ ruleForm.yearEstablished }}</span>
      </p> -->
      <p class="mg-y-6">
        รายได้ประจำปีล่าสุดของบริษัท
        <span class="text-grey"
          ><vue-numeric
            v-model="ruleForm.latestAnnualRevenues"
            :precision="2"
            read-only
          ></vue-numeric>
          บาท</span
        >
      </p>
      <p class="mg-y-6">
        ประเภทของผู้ประกอบการ
        <span class="text-grey">
          <span v-if="ruleForm.operatorType == 1"
            >ผู้ประกอบการค้าขายภายในประเทศ</span
          >
          <span v-else-if="ruleForm.operatorType == 2"
            >ผู้ผลิตเพื่อผู้ส่งออก</span
          >
          <span v-else>ผู้ส่งออก</span>
        </span>
      </p>
      <div v-if="ruleForm.operatorType == 1">
        <p class="mg-y-6">
          มีการผลิต หรือ ขายให้กับผู้ส่งออกโดยตรงหรือไม่
          <span class="text-grey">
            <span v-if="ruleForm.isProduction == 1">มี</span>
            <span v-else-if="ruleForm.isProduction == 2">ไม่มี</span>
          </span>
        </p>
        <p class="mg-y-6">
          ภายในปีนี้มีแผนจะเป็นผู้ส่งออกโดยตรงหรือไม่
          <span class="text-grey">
            <span v-if="ruleForm.exportPlan == 1">มีแผน</span>
            <span v-else-if="ruleForm.exportPlan == 2">ไม่มีแผน</span>
          </span>
        </p>
      </div>
      <div v-else>
        <!-- <p class="mg-y-6">
        กำไรสุทธิประจำปีล่าสุดของบริษัท
        <span class="text-grey"
          ><vue-numeric
            :precision="2"
            v-model="ruleForm.latestAnnualProfits"
            read-only
          ></vue-numeric>
          บาท</span
        >
      </p> -->
        <p class="mg-y-6">
          ประสบการณ์ในการส่งออก
          <span class="text-grey">{{ exportExperience }}</span>
        </p>
        <p class="mg-y-6">
          สัดส่วนในการส่งออก <span class="text-grey">{{ exportRatio }}</span>
        </p>
        <p class="mg-y-6">
          ความสม่ำเสมอของการสั่งซื้อจากผู้ซื้อต่างประเทศรายเดิม
          <span class="text-grey">{{ orderConsistency }}</span>
        </p>
        <p class="mg-y-6">
          ความถี่ในการส่งออกโดยเฉลี่ย
          <span class="text-grey">{{ exportFrequency }}</span>
        </p>
        <p class="mg-y-6">
          วงเงินค้าขายต่อ Shipment โดยเฉลี่ย
          <span class="text-grey">{{ shipment }}</span>
        </p>
        <p class="mg-y-6">
          เทอมการชำระเงินโดยเฉลี่ย
          <span class="text-grey">{{ paymentTerm }}</span>
        </p>
        <p class="mg-y-6">
          มูลค่าการส่งออกโดยเฉลี่ยต่อปี
          <span class="text-grey">{{ exportValue }}</span>
        </p>
        <p class="mg-y-6">
          จำนวนประเทศที่ทำการส่งออก
          <span class="text-grey">{{ exportCountry }}</span>
        </p>
        <!-- <p class="mg-y-6">
          ความคาดหวังจากการเข้าอบรม
          <span class="text-grey">{{ trainingExpectations }}</span>
        </p> -->
      </div>
      <p
        class="mg-y-6"
        v-if="ruleForm.exportPlan == 1 || ruleForm.operatorType == 3"
      >
        ร้อยละที่คาดหวังว่าจะส่งออกได้ / ส่งออกได้เพิ่มขึ้น
        <span v-if="ruleForm.expectedPercentage == 5" class="text-grey"
          >มากกว่าร้อยละ
          <vue-numeric
            v-model="ruleForm.expectedPercentageEtc"
            read-only
          ></vue-numeric
        ></span>
        <span v-else class="text-grey">{{ expectedPercentage }}</span>
      </p>
    </div>
    <el-divider></el-divider>
    <div class="bordered pd-x-3 pd-y-2 mg-b-3 bg-white">
      <h4 class="mg-t-0 text-center">
        ข้อกำหนดและเงื่อนไขการใช้บริการของระบบ TERAK (Terms of Service)
        <br />และ นโยบายความเป็นส่วนตัว (Privacy Policy)
      </h4>
      <p class="text-center">
        (กรุณาอ่านข้อตกลงและเงื่อนไขด้านล่างนี้โดยละเอียด)
      </p>
      <el-divider></el-divider>
      <p>ข้อกำหนดและเงื่อนไขการใช้บริการของระบบ TERAK (Terms of Service)</p>
      <a target="_blank" :href="pdfLink">รายละเอียด</a>
      <p class="mg-t-1">นโยบายความเป็นส่วนตัว (Privacy Policy)</p>
      <a target="_blank" :href="privacyLink">รายละเอียด</a>
    </div>
    <p class="text-center">
      <span class="hidden-lg-and-up">
        ข้าพเจ้าได้ยอมรับ “ข้อกำหนดและเงื่อนไขการใช้บริการของระบบ TERAK
        และนโยบายความเป็นส่วนตัวของ
        <br />ธนาคารเพื่อการส่งออกและนำเข้าแห่งประเทศไทย (ธสน.)”
        ตามที่ระบุไว้ข้างต้น
      </span>
      <span class="hidden-md-and-down">
        ข้าพเจ้าได้ยอมรับ “ข้อกำหนดและเงื่อนไขการใช้บริการของระบบ TERAK
        และนโยบายความเป็นส่วนตัวของ<br />ธนาคารเพื่อการส่งออกและนำเข้าแห่งประเทศไทย
        (ธสน.)” ตามที่ระบุไว้ข้างต้น
      </span>
    </p>
    <div class="text-center mg-t-3">
      <!-- <div @click="$emit('backStep',2)" class="text-grey">ย้อนกลับ</div> -->
      <el-button
        type="primary"
        class="wid220"
        @click="edit ? nextStep() : signupExim1()"
        >ยอมรับ</el-button
      >
    </div>
  </div>
</template>
<script>
import { HTTP } from "@/service/axios";
import Avatar from "vue-avatar";
import moment from "moment";
import "@/service/Auth";
import { EXIM1 } from "@/service/axios";
export default {
  components: {
    Avatar
  },
  computed: {
    titleName() {
      let filter = this.$store.state.titleName.filter(
        a => a.value == this.ruleForm.titleName
      );
      if (filter.length > 0) {
        return filter[0].label;
      }
      return "";
    },
    shipment() {
      let filter = this.$store.state.shipment.filter(
        a => a.value == this.ruleForm.shipment
      );
      if (filter.length > 0) {
        return filter[0].label;
      }
      return "";
    },
    paymentTerm() {
      let filter = this.$store.state.paymentTerm.filter(
        a => a.value == this.ruleForm.paymentTerm
      );
      if (filter.length > 0) {
        return filter[0].label;
      }
      return "";
    },
    exportValue() {
      let filter = this.$store.state.exportValue.filter(
        a => a.value == this.ruleForm.exportValue
      );
      if (filter.length > 0) {
        return filter[0].label;
      }
      return "";
    },
    education() {
      let filter = this.$store.state.education.filter(
        a => a.value == this.ruleForm.education
      );
      if (filter.length > 0) {
        return filter[0].label;
      }
      return "";
    },
    position() {
      let filter = this.$store.state.position.filter(
        a => a.value == this.ruleForm.position
      );
      if (filter.length > 0) {
        return filter[0].label;
      }
      return "";
    },
    businessType() {
      let filter = this.$store.state.businessType.filter(
        a => a.value == this.ruleForm.businessType
      );
      if (filter.length > 0) {
        return filter[0].label;
      }
      return "";
    },
    cateIndustryType() {
      let filter = this.$store.state.industryType.filter(
        a => a.id == this.ruleForm.cateIndustryType
      );
      //console.log("cateIndustryType", filter);
      if (filter.length > 0) {
        return filter[0].label;
      }
      return "";
    },
    industryType() {
      let filter;
      this.$store.state.industryType.map(b => {
        if (
          b.id === this.ruleForm.cateIndustryType &&
          this.ruleForm.industryType != null
        ) {
          filter = b.subIndustry.filter(
            a => a.value == this.ruleForm.industryType
          );
        }
      });
      //console.log("industryType", filter);
      if (typeof filter != "undefined" && filter.length > 0) {
        return filter[0].label;
      }
      return "";
    },
    memberOf() {
      let data = [];
      this.ruleForm.memberOf.map((value, index) => {
        if (value != 6 && value != 7) {
          let filter = this.$store.state.memberOfOrganize.filter(
            a => a.value == value
          );
          if (filter.length > 0) {
            data.push({ number: filter[0].value, etc: filter[0].label });
          }
        } else if (value == 7) {
          let filter = this.$store.state.memberOfOrganize.filter(
            a => a.value == value
          );
          if (filter.length > 0) {
            data.push({ number: 7, etc: this.ruleForm.memberOfEtc });
          }
        } else {
          data.push({ number: 6, etc: this.ruleForm.memberOfEtc });
        }
      });
      return data;
    },
    employment() {
      let filter = this.$store.state.employment.filter(
        a => a.value == this.ruleForm.employment
      );
      if (filter.length > 0) {
        return filter[0].label;
      }
      return "";
    },
    exportRatio() {
      let filter = this.$store.state.exportRatio.filter(
        a => a.value == this.ruleForm.exportRatio
      );
      if (filter.length > 0) {
        return filter[0].label;
      }
      return "";
    },
    orderConsistency() {
      let filter = this.$store.state.orderConsistency.filter(
        a => a.value == this.ruleForm.orderConsistency
      );
      if (filter.length > 0) {
        return filter[0].label;
      }
      return "";
    },
    exportFrequency() {
      let filter = this.$store.state.exportFrequency.filter(
        a => a.value == this.ruleForm.exportFrequency
      );
      if (filter.length > 0) {
        return filter[0].label;
      }
      return "";
    },
    exportCountry() {
      let filter = this.$store.state.exportCountry.filter(
        a => a.value == this.ruleForm.exportCountry
      );
      if (filter.length > 0) {
        return filter[0].label;
      }
      return "";
    },
    exportExperience() {
      let filter = this.$store.state.exportExperience.filter(
        a => a.value == this.ruleForm.exportExperience
      );
      if (filter.length > 0) {
        return filter[0].label;
      }
      return "";
    },
    trainingExpectations() {
      let filter = this.$store.state.trainingExpectations.filter(
        a => a.value == this.ruleForm.trainingExpectations
      );
      if (filter.length > 0) {
        return filter[0].label;
      }
      return "";
    },
    expectedPercentage() {
      let filter = this.$store.state.expectedPercentage.filter(
        a => a.value == this.ruleForm.expectedPercentage
      );
      if (filter.length > 0) {
        return filter[0].label;
      }
      return "";
    }
  },
  props: {
    ruleForm: {
      type: Object,
      default: {}
    },
    edit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      imgUrl: process.env.VUE_APP_IMAGE_URL,
      pdfLink: require("@/assets/terms-of-service.pdf"),
      privacyLink: "https://www.exim.go.th/th/privacypolicy.aspx",
      eximredirect: process.env.VUE_APP_OIDC_SERVE,
      baseUrl: process.env.VUE_APP_URL,
      termLink:
        "https://www.exim.go.th/th/EXIM-SLA/%E0%B8%82%E0%B8%AD%E0%B8%95%E0%B8%81%E0%B8%A5%E0%B8%87%E0%B8%A3%E0%B8%B0%E0%B8%94%E0%B8%9A%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B9%83%E0%B8%AB%E0%B8%9A%E0%B8%A3%E0%B8%81%E0%B8%B2%E0%B8%A3-(1).aspx",
      loading: false,
      rules: {},
      sPhrase:'',
    };
  },
  mounted(){
    this.getSphase();
  },
  methods: {
    async getSphase(){
      try {
        HTTP.defaults.headers.common.Authorization = `Bearer ${this.$store.state.sphrase}`;
        let res = await HTTP.get(`key/sign`)
        if(res.data.success){
          this.sPhrase = res.data.data
        }
      } catch (error) {
        
      }
    },
    getBlobImg(data) {
      return data;
    },
    signupExim1() {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          "X-CSRF-TOKEN": "",
        }
      };
      let formData = new FormData();
      formData.append("Name", this.ruleForm.name);
      formData.append("Surname", this.ruleForm.surname);
      formData.append("PhoneNumber", this.ruleForm.mobilephone);
      formData.append("Email", this.ruleForm.email);
      formData.append("Password", this.ruleForm.password);
      formData.append("ConfirmPassword", this.ruleForm.password);
      formData.append("MultiFactorAuthentication", "1");
      formData.append("AllowPolicy", "true");
      formData.append("AllowAccess", "true");
      EXIM1.post("/register", formData, config)
        .then(res => {
          if (res.data.success) {
            this.nextStep();
          } else {
            if (res.data.data == "Email Alredy Exist.") {
              // this.$message.error("Email Alredy Exist In Exim1");
              this.$alert( `<h2>มีบัญชีผู้ใช้แล้ว กรุณาเข้าสู่ระบบ EXIM1</h2><a href="${ this.eximredirect }/Account/Login?ReturnUrl=${ this.baseUrl}signin/redirect">เข้าสู่ระบบ</a>`, 'HTML String', {
                dangerouslyUseHTMLString: true
              } );
            } else {
              this.$message.error(res.data.data);
            }
          }
        })
        .catch(e => {
          this.$message.error("Please contact admin Exim1");
          console.log("signupExim1 error: ", e);
        });
    },
    nextStep() {
      this.loading = true;
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          "X-CSRF-TOKEN": this.$csrfToken
        }
      };
      let formData = new FormData();
      let tempPassword = this.CryptoJS.AES.encrypt(
        this.ruleForm.password,
        this.sPhrase
      ).toString();

      formData.append(
        "profileImg",
        this.ruleForm.profileImg ? this.ruleForm.profileImg.raw : ""
      );
      formData.append("email", this.ruleForm.email);
      formData.append("password", tempPassword);
      formData.append("titleName", this.ruleForm.titleName);
      formData.append("name", this.ruleForm.name);
      formData.append("surname", this.ruleForm.surname);
      formData.append("gender", this.ruleForm.gender);
      formData.append("age", this.ruleForm.age);
      formData.append("education", this.ruleForm.education);
      formData.append("mobilephone", this.ruleForm.mobilephone);
      formData.append("companyName", this.ruleForm.companyName);
      formData.append("corporateNumber", this.ruleForm.corporateNumber);
      formData.append(
        "position",
        this.ruleForm.position == null || !this.ruleForm.position
          ? 0
          : this.ruleForm.position
      );
      formData.append("businessType", this.ruleForm.businessType);
      formData.append("cateIndustryType", this.ruleForm.cateIndustryType);
      formData.append("industryType", this.ruleForm.industryType);
      formData.append("industryTypeEtc", this.ruleForm.industryTypeEtc);
      formData.append("memberOf", JSON.stringify(this.memberOf));
      formData.append("memberOfEtc", this.ruleForm.memberOfEtc);
      formData.append(
        "employment",
        this.ruleForm.employment == null || !this.ruleForm.employment
          ? 0
          : this.ruleForm.employment
      );
      // formData.append("yearEstablished", Number(this.ruleForm.yearEstablished));
      formData.append(
        "latestAnnualRevenues",
        this.ruleForm.latestAnnualRevenues
      );
      // formData.append("latestAnnualProfits", this.ruleForm.latestAnnualProfits);
      formData.append("exportExperience", this.ruleForm.exportExperience);
      formData.append(
        "exportRatio",
        this.ruleForm.exportRatio == null || !this.ruleForm.exportRatio
          ? 0
          : this.ruleForm.exportRatio
      );
      formData.append(
        "exportSales",
        this.ruleForm.exportSales == null || !this.ruleForm.exportSales
          ? 0
          : this.ruleForm.exportSales
      );
      formData.append(
        "orderConsistency",
        this.ruleForm.orderConsistency == null ||
          !this.ruleForm.orderConsistency
          ? 0
          : this.ruleForm.orderConsistency
      );
      formData.append(
        "exportFrequency",
        this.ruleForm.exportFrequency == null || !this.ruleForm.exportFrequency
          ? 0
          : this.ruleForm.exportFrequency
      );
      formData.append(
        "exportCountry",
        this.ruleForm.exportCountry == null || !this.ruleForm.exportCountry
          ? 0
          : this.ruleForm.exportCountry
      );
      formData.append(
        "trainingExpectations",
        this.ruleForm.trainingExpectations
      );
      formData.append(
        "moreExportExpectations",
        this.ruleForm.moreExportExpectations
      );
      formData.append(
        "expectedPercentage",
        this.ruleForm.expectedPercentage == null ||
          !this.ruleForm.expectedPercentage
          ? 0
          : this.ruleForm.expectedPercentage
      );
      formData.append(
        "expectedPercentageEtc",
        this.ruleForm.expectedPercentageEtc
      );
      formData.append("privacyPolicy", this.ruleForm.privacyPolicy);
      formData.append("termsOfService", this.ruleForm.termsOfService);
      formData.append("consent", this.ruleForm.consent);
      formData.append("operatorType", this.ruleForm.operatorType);
      formData.append(
        "exportPlan",
        this.ruleForm.exportPlan == null || !this.ruleForm.exportPlan
          ? 0
          : this.ruleForm.exportPlan
      );
      formData.append(
        "isProduction",
        this.ruleForm.isProduction == null || !this.ruleForm.isProduction
          ? 0
          : this.ruleForm.isProduction
      );
      formData.append("shipment", this.ruleForm.shipment);
      formData.append("paymentTerm", this.ruleForm.paymentTerm);
      formData.append("exportValue", this.ruleForm.exportValue);
      formData.append(
        "referId",
        typeof this.ruleForm.referId == "undefined" ? "" : this.ruleForm.referId
      );
      formData.append(
        "startJoin",
        typeof this.ruleForm.startJoin == "undefined" ||
          this.ruleForm.startJoin == ""
          ? ""
          : moment(this.ruleForm.startJoin).format("YYYY-MM-DD HH:mm:ss")
      );
      formData.append(
        "endJoin",
        typeof this.ruleForm.endJoin == "undefined" ||
          this.ruleForm.endJoin == ""
          ? ""
          : moment(this.ruleForm.endJoin).format("YYYY-MM-DD HH:mm:ss")
      );
      // this.$emit('nextStep')
      formData.append("_csrf", this.$csrfToken);

      HTTP.post(`sign/up`, formData, config)
        .then(res => {
          //console.log(`sign/up`, res);
          if (res.data.success) {
            this.$emit("nextStep", this.ruleForm);
            this.loading = false;
          } else {
            this.loading = false;
            if (res.data.data == "email is not work.")
              this.$message.error("อีเมลนี้ถูกใช้งานแล้ว");
          }
        })
        .catch(e => {
          this.$message.error(e);
          console.log(`sign/up error`, e);
          this.loading = false;
        });
    }
  }
};
</script>
<style scoped>
.fa-pencil-alt {
  font-size: 10px;
}
p {
  margin: 5px 0;
}
</style>
