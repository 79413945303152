<template>
  <div>
    <defaultTemplate v-if="finish == false">
      <div class="main-slot" v-loading="fullLoad">
        <Layout>
          <h1 class="text-center font-weight-bold mg-b-1">สมัครสมาชิก</h1>
          <el-steps :active="active" align-center class="mg-t-1">
            <el-step title="อีเมล" :class="active == 0 ? 'tri-reg' : ''">
              <div slot="icon">
                <div
                  class="bg-step"
                  :class="
                    active == 0
                      ? ''
                      : active > 0
                      ? 'step-success'
                      : 'step-before'
                  "
                >
                  <img class="mw-100" src="@/assets/mail.png" alt="" />
                  <i class="fas fa-check"></i>
                </div>
              </div>
            </el-step>
            <el-step title="ข้อมูลส่วนตัว" :class="active == 1 ? 'tri-reg' : ''"
              ><div slot="icon">
                <div
                  class="bg-step"
                  :class="
                    active == 1
                      ? ''
                      : active > 1
                      ? 'step-success'
                      : 'step-before'
                  "
                >
                  <img class="mw-100" src="@/assets/user.png" alt="" />
                  <i class="fas fa-check"></i>
                </div></div
            ></el-step>
            <el-step title="ข้อมูลบริษัท" :class="active == 2 ? 'tri-reg' : ''"
              ><div slot="icon">
                <div
                  class="bg-step"
                  :class="
                    active == 2
                      ? ''
                      : active > 2
                      ? 'step-success'
                      : 'step-before'
                  "
                >
                  <img class="mw-100" src="@/assets/bag.png" alt="" />
                  <i class="fas fa-check"></i>
                </div></div
            ></el-step>
            <el-step title="ยืนยันข้อมูล" :class="active == 3 ? 'tri-reg' : ''"
              ><div slot="icon">
                <div
                  class="bg-step"
                  :class="
                    active == 3
                      ? ''
                      : active > 3
                      ? 'step-success'
                      : 'step-before'
                  "
                >
                  <img class="mw-100" src="@/assets/like.png" alt="" />
                  <i class="fas fa-check"></i>
                </div></div
            ></el-step>
          </el-steps>
        </Layout>
        <div class="bg-user-second not-tri pd-t-1 padding-grid form-register">
          <Layout>
            <LoginForm
              v-if="active == 0"
              :ruleForm="ruleForm"
              :edit="editData"
              @nextStep="active++"/>
            <PersonInformation
              v-if="active == 1"
              :ruleForm="ruleForm"
              :edit="editData"
              @nextStep="active++"/>
            <CompanyInformation
              v-if="active == 2"
              :ruleForm="ruleForm"
              @nextStep="active++"
              @backStep="active--"/>
            <ConfirmData
              v-if="active == 3"
              :ruleForm="ruleForm"
              :edit="editData"
              @backStep="chooseStep"
              @nextStep="registerSuccess"
          /></Layout>
        </div>
      </div>
    </defaultTemplate>
    <defaultTemplate
      v-else
      class="text-center text-white bg-primary"
      page="signup"
    >
      <div class="text-center pd-5 position-relative main-slot">
        <!-- <el-button
          type="success"
          style="cursor: unset"
          class="font-44 mg-y-1 pd-4"
          circle
          ><i class="fas fa-check font-38"></i
        ></el-button> -->
        <svg
          class="checkmark"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 52 52"
        >
          <circle
            class="checkmark__circle"
            cx="26"
            cy="26"
            r="25"
            fill="none"
          />
          <path
            class="checkmark__check"
            fill="none"
            d="M14.1 27.2l7.1 7.2 16.7-16.8"
          />
        </svg>
        <p class="font-24">คุณ{{ ruleForm.name }} {{ ruleForm.surname }}</p>
        <h1 class="text-success" v-if="projectCode">
          สมัครสมาชิกและเข้าร่วมโครงการสำเร็จแล้ว!
        </h1>
        <h1 class="text-success" v-else>สมัครสมาชิกสำเร็จแล้ว!</h1>
        <h4 v-if="projectCode">
          โครงการที่เข้าร่วม : {{ resData.obj.projectName }}
        </h4>
        <el-row :gutter="20" type="flex" justify="center">
          <el-col :sm="8" :md="6" :lg="5" :xl="4" :offset="0">
            <div class="signin-button">
              <el-button class="w-100 font-26" type="danger" @click="userSignIn"
                >ดำเนินการต่อ</el-button
              >
            </div>
          </el-col>
        </el-row>
      </div>
    </defaultTemplate>
    <el-dialog
      v-if="dialogCode"
      :visible.sync="dialogCode"
      center
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      class="expDialog signUp"
      :class="resData.data == 'Is not time yet.' ? 'not-time' : ''"
      :before-close="closeModal"
    >
      <div class="is-flex ai-center mg-b-6">
        <p class="mg-0 mg-r-6 font-weight-bold">ชื่อโครงการ</p>
        <p class="mg-0" style="color: #9e9e9e">
          <span
            v-if="
              resData.data == 'not found data' ||
                resData.data == 'Refer code not work'
            "
            >ไม่พบชื่อโครงการ</span
          >
          <span v-else>
            {{ resData.obj.projectName }}
          </span>
        </p>
      </div>
      <div class="is-flex ai-center">
        <p class="mg-0 mg-r-6 font-weight-bold">
          {{
            resData.data == "Refer code time out."
              ? "วันที่จัดโครงการ"
              : "วันที่เปิดรับสมัคร"
          }}
        </p>
        <p class="mg-0" style="color: #9e9e9e">
          <span
            v-if="
              resData.data == 'not found data' ||
                resData.data == 'Refer code not work'
            "
            >ไม่พบวันที่เปิดรับสมัคร"</span
          >
          <span v-else>
            {{
              resData.data == "Refer code time out."
                ? moment(resData.obj.endJoin)
                    .add(543, "year")
                    .format("D MMM yy")
                : moment(resData.obj.startDate)
                    .add(543, "year")
                    .format("D MMM yy") +
                  " - " +
                  moment(resData.obj.endDate)
                    .add(543, "year")
                    .format("D MMM yy")
            }}
          </span>
        </p>
      </div>
      <div slot="footer" class="text-left">
        <p
          v-if="resData.data == 'Refer code time out.'"
          class="cr-pointer"
          @click="closeModal"
        >
          ปิดรับสมัครโครงการแล้ว
        </p>

        <p
          v-else-if="
            resData.data == 'not found data' ||
              resData.data == 'Refer code not work'
          "
          class="cr-pointer"
          @click="closeModal"
        >
          ลิงก์ลงทะเบียนไม่ถูกต้อง
        </p>
        <p
          v-if="resData.data == 'Is not time yet.'"
          class="cr-pointer"
          @click="closeModal"
        >
          ขณะนี้ยังไม่อยู่ในช่วงเปิดรับสมัครโครงการ
        </p>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import CompanyInformation from "@/components/Signup/CompanyInformation"
  import ConfirmData from "@/components/Signup/ConfirmData"
  import LoginForm from "@/components/Signup/LoginForm"
  import PersonInformation from "@/components/Signup/PersonInformation"
  import "@/service/Auth"
  import "@/service/Refer"
  import defaultTemplate from "@/template/DefaultTemplate"
  import Layout from "@/template/LayoutDefaultTemplate"
  import moment from "moment"
  export default {
    components: {
      defaultTemplate,
      LoginForm,
      PersonInformation,
      CompanyInformation,
      ConfirmData,
      Layout,
    },
    computed: {
      userOidc() {
        let data = this.$store.state.useroidc
        this.ruleForm.email = data.profile.email.toString()
        this.ruleForm.name = data.profile.firstName.toString()
        this.ruleForm.surname = data.profile.lastName.toString()
        return data
      },
    },
    mounted() {
      this.getSphase();
      // if (
      //   typeof this.$route.query.code != "undefined" &&
      //   this.$route.query.code != ""
      // )
      // {
      //   this.$router.push("/maintenance")
      // }
      if (
        typeof this.$route.query.app != "undefined" &&
        this.$route.query.app == "ex1"
      ) {
        this.editData = true
      }
      if (this.$store.state.useroidc && this.$route.query.app == "ex1") {
        this.ruleForm.email = this.$store.state.useroidc.profile.email.toString()
        this.ruleForm.name = this.$store.state.useroidc.profile.firstName.toString()
        this.ruleForm.surname = this.$store.state.useroidc.profile.lastName.toString()
        this.ruleForm.mobilephone = this.$store.state.useroidc.profile.phone.toString()
        this.editData = true
      }

      if (
        typeof this.token != "undefined" &&
        this.token != null &&
        this.token != "" &&
        typeof this.$route.query.code != "undefined"
      ) {
        this.fullLoad = true
        this.checkLogin(this.token, this.$route.query.code)
      } else if (
        typeof this.token != "undefined" &&
        this.token != null &&
        this.token != "" &&
        typeof this.$route.query.code == "undefined"
      ) {
        this.$confirm("กรุณาออกจากระบบก่อนสมัครสมาชิก", {
          confirmButtonText: "OK",
          showCancelButton: false,
          type: "error",
        })
          .then(() => {
            this.checkLogin(this.token)
          })
          .catch(() => {
            this.checkLogin(this.token)
          })
      } else if (
        typeof this.$route.query.code != "undefined" &&
        this.$route.query.code != ""
      ) {
        this.checkReferCode(this.$route.query.code)
      }
    },
    methods: {
      closeModal() {
        if (
          typeof this.token != "undefined" &&
          this.token != null &&
          this.token != ""
        ) {
          this.$router.push(`/sign-in`)
        } else {
          this.$router.push(`/sign-in`).catch()
        }
        this.dialogCode = false
      },
      checkDateExp(val) {
        let now = new Date()
        let dateNow = moment(now).format("YYYY-MM-DD")
        let dateEnd = moment(val).format("YYYY-MM-DD")
        if (dateNow > dateEnd) {
          return true
        } else if (dateNow == dateEnd) {
          return false
        } else {
          return false
        }
      },
      async checkReferCode(code) {
        let res = await this.getReferCode(code)
        if (res.data.success) {
          this.resData = res.data
          this.ruleForm.referId = res.data.obj.id
          this.ruleForm.startJoin = res.data.obj.startJoin
          this.ruleForm.endJoin = res.data.obj.endJoin
        } else {
          if (res.data.data == "Refer code time out.") {
            this.$router.push(this.$route.path)
          } else {
            //this.alertCatchError(res.data.data);
            this.$router.push(this.$route.path)
          }
          this.resData = res.data
          this.dialogCode = true
        }
      },
      registerSuccess(ruleForm) {
        this.finish = true
        this.ruleForm = ruleForm
      },
      userSignIn() {
        this.$router.push("/sign-in")
      },
      // async userSignIn() {
      //   let tempPassword = this.CryptoJS.AES.encrypt(
      //     this.ruleForm.password,
      //     this.sPhrase
      //   ).toString();
      //   const signinAuth = {
      //     email: this.ruleForm.email,
      //     password: tempPassword,
      //     _csrf: this.$csrfToken
      //   };
      //   let token = jwt.sign( { email: this.ruleForm.email }, this.appSecret );
      //   let res = await this.getTokenUser(token);
      //   console.log("-----------------------", res.data.obj);
      //   let userData = await this.getUserById(res.data.obj.token);
      //   console.log("userData :", userData);
      //   if (userData.data.success) {
      //     this.$store.commit(
      //       "SET_USER",
      //       JSON.parse(JSON.stringify(userData.data.obj))
      //     );
      //     this.$store.commit("SET_TOKEN", res.data.obj.token);
      //     if (userData.data.obj.role == 0) {
      //       let checkData = await this.checkCompanyData(userData.data.obj);
      //       if (checkData) {
      //         let url = "/edit-company";
      //         this.$router.push(url).catch(err => {});
      //       } else {
      //         this.$router.push("/result?user=new").catch(err => {});
      //       }
      //     } else {
      //       this.directUrl(userData.data.obj, "");
      //     }
      //   }
      //   //let res = await this.signIn( signinAuth );
      //   //if ( res.data.success )
      //   //{
      //   //   let userData = await this.getUserById(res.data.token);
      //   //   if (userData.data.success) {
      //   //     this.$store.commit(
      //   //       "SET_USER",
      //   //       JSON.parse(JSON.stringify(userData.data.obj))
      //   //     );
      //   //     this.$store.commit("SET_TOKEN", res.data.token);
      //   //     if (userData.data.obj.role == 0) {
      //   //       let checkData = await this.checkCompanyData(userData.data.obj);
      //   //       if (checkData) {
      //   //         let url = "/edit-company";
      //   //         this.$router.push(url).catch(err => {});
      //   //       } else {
      //   //         this.$router.push("/result?user=new").catch(err => {});
      //   //       }
      //   //     } else {
      //   //       this.directUrl(userData.data.obj, "");
      //   //     }
      //   //   }
      //   //} else {
      //   // if (res.data.data == "wrong email or password.") {
      //   //   this.$message({
      //   //     showClose: true,
      //   //     message: "รหัสผ่านไม่ถูกต้อง",
      //   //     type: "error"
      //   //   });
      //   // } else if (res.data.data == "not found email.") {
      //   //   this.$message({
      //   //     showClose: true,
      //   //     message: "ขออภัยกรุณาตรวจสอบอีเมลของท่านอีกครั้ง",
      //   //     type: "error"
      //   //   });
      //   // } else {
      //   //   this.$message({
      //   //     showClose: true,
      //   //     message: res.data.data,
      //   //     type: "error"
      //   //   });
      //   // }
      //   this.loading = false;
      //   //}
      // },
      chooseStep(e) {
        this.active = e
      },
      async getSphase(){
        try {
          HTTP.defaults.headers.common.Authorization = `Bearer ${this.$store.state.sphrase}`;
          let res = await HTTP.get(`key/sign`)
          if(res.data.success){
            this.sPhrase = res.data.data
          }
        } catch (error) {
          
        }
      },
    },
    data() {
      return {
        editData: false,
        fullLoad: false,
        color: "",
        sPhrase: '',
        projectCode:
          typeof this.$route.query.code != "undefined"
            ? this.$route.query.code
            : false,
        dialogCode: false,
        resData: [],
        finish: false,
        ruleForm: {
          referId: "",
          startJoin: "",
          endJoin: "",
          email: "",
          password: "",
          repassword: "",
          titleName: 1,
          name: "",
          surname: "",
          gender: 1,
          age: 18,
          education: 1,
          mobilephone: null,
          companyName: "",
          corporateNumber: "",
          position: null,
          businessType: null,
          industryType: null,
          cateIndustryType: null,
          industryTypeEtc: "",
          employment: null,
          memberOf: null,
          memberOfEtc: "",
          yearEstablished: null,
          latestAnnualRevenues: 0,
          latestAnnualProfits: null,
          exportExperience: null,
          exportRatio: null,
          exportSales: null,
          orderConsistency: null,
          exportFrequency: null,
          exportCountry: null,
          trainingExpectations: null,
          moreExportExpectations: null,
          expectedPercentage: null,
          expectedPercentageEtc: 0,
          profileImg: "",
          privacyPolicy: 1,
          termsOfService: 1,
          consent: 0,
          operatorType: null,
          exportPlan: null,
          isProduction: null,
          shipment: null,
          paymentTerm: null,
          exportValue: null,
        },
        active: 0,
      }
    },
  }
</script>

<style scoped>
  @media (min-width: 1920px) {
    .footer {
      width: 100%;
      position: absolute;
      bottom: 25px;
      margin: 0 auto;
    }
    .main-slot {
      min-height: calc(100vh - 235px);
    }
  }

  .main-slot {
    min-height: calc(100vh - 402px);
  }

  @media (min-width: 1366px) and (max-width: 1367px) {
    .padding-grid {
      min-height: calc(100vh - 437px) !important;
    }
    .main-slot {
      min-height: calc(100vh - 146px) !important;
    }
  }

  @media (min-width: 1281px) {
    .padding-grid {
      min-height: 54vh !important;
    }
    .main-slot {
      min-height: calc(100vh - 146px) !important;
    }
  }

  @media (min-width: 1024px) and (max-width: 1280px) {
    .padding-grid {
      min-height: calc(100vh - 444px) !important;
    }
    .main-slot {
      min-height: calc(100vh - 146px) !important;
    }
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    .bg-user-second {
      height: 63vh;
    }
    .main-slot {
      min-height: calc(100vh - 200px) !important;
    }
  }
  @media (min-width: 767px) and (max-width: 834px) {
    .padding-grid {
      min-height: calc(100vh - 444px) !important;
    }
    .main-slot {
      min-height: calc(100vh - 146px) !important;
    }
  }

  @media (max-width: 767px) {
    .main-slot {
      min-height: calc(100vh - 355px) !important;
    }
  }

  @media (max-width: 414px) {
    .main-slot {
      min-height: calc(100vh - 355px) !important;
    }
  }

  @media (max-width: 375px) {
    .main-slot {
      min-height: calc(100vh - 349px) !important;
    }
  }
</style>
